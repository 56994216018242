import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from "@/views/Landing/Landing"
import Register from "@/views/Register/Register"
import Owner from "@/views/Owner/Owner"
import POS from "@/views/POS/POS"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Landing
  },
  {
    path: '/pos',
    name: 'POS',
    component: POS
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/owner',
    name: 'Owner',
    component: Owner
  }
]

const router = new VueRouter({
  routes
})

export default router
