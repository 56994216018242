import firebase from 'firebase'

// Initialize Firebase
const config = {
  apiKey: "AIzaSyCQSsoXLkQ1oypd4PrxMOsbEhS5XgYVFdA",
  authDomain: "identity4good.firebaseapp.com",
  databaseURL: "https://identity4good.firebaseio.com",
  projectId: "identity4good",
  storageBucket: "identity4good.appspot.com",
  messagingSenderId: "1052796377875",
  appId: "1:1052796377875:web:8af3c62c4835c9d8d6d8e6",
  measurementId: "G-Z06RPTE4SL"
}

if (!firebase.apps.length) {
  firebase.initializeApp(config)
} else {
  firebase.app()
}


export default firebase
