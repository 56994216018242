<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data: () => ({
    register: '',
    verify: null
  }),
  beforeDestroy() {
    clearInterval(this.verify)
  },
  beforeUpdate() {
    clearInterval(this.verify)
  },
  async mounted() {
    const { hash } = this.$route.query
    if (hash) {
      this.setHash(hash)
    }
    if (navigator.userAgent.includes('TPS') && this.$device.mobile) {
      this.$router.push({ name: 'POS' })
    }
    try {
      const service = await this.$axios.get('http://127.0.0.1:9555/ping')
      if (service.data === 'pong') {
        const hash = await this.$axios.get('http://127.0.0.1:9555/hash')
        this.register = hash.data
        await this.$axios.get('http://127.0.0.1:9555/setpay').then((result) => {
          console.log(result)
        }).catch((error) => {
          console.log(error)
        })
        // await this.verifyRequest()
        // this.verify = setInterval(() => { this.verifyRequest() }, 1000 * 60)
      }
    } catch (e) {
      console.log(e)
    }

  },
  methods: {
    setHash (hash) {
      localStorage.setItem('hash', hash)
    },
    async verifyRequest () {
      const result = await this.$axios.get('https://pay4good.com/asset/verifyAssetRequest', {
        params: {
          hash: this.register,
          crc: Date.now()
        }
      })
      console.log(result.data.request)
      if (result.data.request) {
        await this.$axios.post('http://127.0.0.1:9555/update', {
          url: result.data.request.url,
          key: result.data.request.key
        })
      }
    }
  }
};
</script>

<style>

body {
  overflow: hidden;
}

::-webkit-scrollbar {
  display: none;
}

#app.theme--dark {
  background: rgb(0,91,136);
  background: linear-gradient(136deg, rgba(0,91,136,1) 0%, rgba(0,44,69,1) 100%);
}

#app.theme--light {
  background: rgb(234,242,246);
  background: linear-gradient(136deg, rgba(234,242,246,1) 0%, rgba(156,199,223,1) 100%);
}

</style>
