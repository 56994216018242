<template>
  <div class="Register">
    <div class="box mx-16 my-8" align="center">
      <v-container v-if="loading" fluid class="content">
        <v-row>
          <v-col align-self="center" align="center">
            <v-progress-circular color="white" indeterminate width="10" size="200">
              Registering...
            </v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="!loading" fluid class="content fill-height">
        <v-row class="mt-1">
          <v-col align-self="center" align="center">
            <div class="boxTitle">Register</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <v-text-field @focus="show" v-model="name" dark label="Name Store" style="max-width: 400px"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <v-select :items="countries" v-model="country" item-value="id" item-text="name" dark label="Country" style="max-width: 400px"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <v-btn @click="register">Register</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="right" class="pt-0">
            <v-img :src="require('@/assets/box4good_white.svg')" height="auto" max-width="30%" contain></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <vue-touch-keyboard v-if="visible && !loading" :layout="layout" :cancel="hide" :accept="accept"
                        :input="input"/>
  </div>
</template>

<script>
import './_Register.scss'
import VueTouchKeyboard from 'vue-touch-keyboard'
import 'vue-touch-keyboard/dist/vue-touch-keyboard.css'

export default {
  name: 'Register',
  data () {
    return {
      name: '',
      country: null,
      visible: false,
      version: '',
      loading: false,
      countries: [
        { id: 'US', name: 'United States' },
        { id: 'CA', name: 'Canada' },
        { id: 'BR', name: 'Brazil' },
        { id: 'SV', name: 'El Salvador' },
        { id: 'CL', name: 'Chile' }
      ],
      layout: {
        _meta: {
          tab: { key: '\t', text: 'Tab', width: 60, classes: 'control' },
          shiftl: { keySet: 'shifted', text: 'Shift', width: 100, classes: 'control' },
          shiftr: { keySet: 'shifted', text: 'Shift', width: 100, classes: 'control' },
          caps: { keySet: 'capsed', text: 'Caps lock', width: 80, classes: 'control' },
          space: { key: ' ', text: 'Space', width: 180 },
          enter: { key: '\r\n', text: 'Enter', width: 80, classes: 'control' },
          backspace: { func: 'backspace', classes: 'control backspace', width: 65 },
          accept: { func: 'accept', text: 'Accept', classes: 'control featured' }
        },
        default: [
          '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
          '{tab} q w e r t y u i o p [ ] \\',
          "{caps} a s d f g h j k l ; ' {enter}",
          '{shiftl} z x c v b n m , . / {shiftr}',
          '{space} {accept}'
        ],
        shifted: [
          '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
          '{tab} Q W E R T Y U I O P { } |',
          '{caps} A S D F G H J K L : " {enter}',
          '{shiftl} Z X C V B N M < > ? {shiftr}',
          '{space} {accept}'
        ],
        capsed: [
          '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
          '{tab} Q W E R T Y U I O P [ ] \\',
          "{caps} A S D F G H J K L ; ' {enter}",
          '{shiftl} Z X C V B N M , . / {shiftr}',
          '{space} {accept}'
        ]
      },
      input: null
    }
  },
  components: { 'vue-touch-keyboard': VueTouchKeyboard.component },
  async mounted() {
    try {
      const service = await this.$axios.get('http://127.0.0.1:9555/ping')
      if (service.data !== 'pong') {
        await this.$router.push({name: 'Welcome'})
      }
    } catch (e) {
      await this.$router.push({name: 'Welcome'})
    }
    try {
      await this.$axios.get('http://127.0.0.1:9555/hash')
      await this.$router.push({name: 'Home'})
    } catch (e) {
      console.log(e)
    }
    this.version = 'v' + process.env.VUE_APP_VERSION
  },
  methods: {
    accept () {
      this.hide()
    },
    hide () {
      this.visible = false
    },
    show (e) {
      this.input = e.target
      this.visible = true
    },
    async register () {
      this.loading = true
      const result = await this.$axios.get('https://pay4good.com/asset/registerAssetBox', {
        params: {
          name: this.name,
          country: this.country,
          crc: Date.now()
        }
      })
      await this.$axios.post('http://127.0.0.1:9555/hash', {
        hash: result.data.asset.assetHash
      })
      await this.$router.push({name: 'Home'})
    }
  }
}
</script>
